import gql from 'graphql-tag'
import { publicClient } from '@/plugins/vue-apollo.plugin'
import { OrderPreviewType } from '../schema/public.graphql.types'
import { OrderImport } from '@/models/order/Order'
import { ulog } from '@/common/utils/AppConsole'

export namespace GQLPublicOrderQuery {

  const query = gql`
    query PublicOrderQuery($hash: String!, $ip: String) {
      getOrderPreview (hash: $hash, ip: $ip) {
        success
        message
        order {
          ... on object_Order {
            id, fullpath, key,
            orderType, orderNumber, isPriority, isEmergency
            internalNote, creationDateInquiry, creationDateOrder
            state,
            configuratorVersion

            branchOffice {
              ... on object_BranchOffice {
                id, key, fullpath,
                name, applicationMode, code, address, city, email, phone
                commission, expedition,
                businessRegisterName, billingCity, billingPostalCode,
                companyIdNumber, companyTaxNumber,
                billingRegisteredIn, url,
                stampAsset {
                  ...on asset { id, fullpath, filename, filesize, type, mimetype }
                },

                employees {
                  ...on fieldcollection_emloyee {
                    name, position, phone, email,
                    photo {
                      ...on hotspotimage {
                        image {
                          ...on asset { id, fullpath, filename, filesize, type, mimetype }
                        }
                      }
                    }
                  }
                }

                location {
                  ...on Geopoint {
                    longitude, longitude
                  }
                },

                holidayFrom,
                holidayTill,
                morningAssemblyTime,
                afternoonAssemblyTime,
                eveningAssemblyTime,

                workerGroups {
                  ... on object_WorkerGroup {
                    id, key, fullpath,
                    workerGroupID, availableMorning, availableAfternoon, availableEvening, certificate,

                    leadingWorker {
                      ...on object_Worker {
                        id, key, fullpath,
                        firstName, lastName, phone, email
                      }
                    },

                    workers {
                      ...on object_Worker {
                        id, key, fullpath,
                        firstName, lastName, phone, email
                      }
                    },
                  }
                }

                apiUser {
                  ...on object_ApiUser {
                    id, key, fullpath,
                    name, email, passwordResetHash,
                  }
                }
              }
            }

            invoiceProformaState,
            invoiceProformaAsset {
              ... on asset { id, fullpath, filename, filesize, type, mimetype }
            },
            invoiceState,
            invoiceAsset {
              ... on asset { id, fullpath, filename, filesize, type, mimetype }
            }
            invoiceNumber,
            branchCommissionState,
            cancellationNote,
            invoicingData {
              ...on object_InvoicingData {
                id, key, fullpath,
                invoicedEntity, directSell,
                firstName, lastName, verificationType, birthdate,
                companyName, ico, dic,
                street, streetNumber, city, postcode,
                vatRate, liability, paymentMethod
              }
            }
            contactData {
              ... on object_ContactData {
                id, fullpath, key,
                firstName, lastName, phone, email, note
              }
            }

            signedOrderConfirmation { ...on asset { id, fullpath, filename, filesize, type, mimetype } }

            configurations {
              ... on object_OrderDoorConfiguration {
                id, fullpath, key,
                quantity, name, note,
                installationDate, installationMorning, installationAfternoon,
                directSellTransport, installationState,

                shippingStreet,
                shippingStreetNumber,
                shippingCity,
                shippingPostcode,
                street,
                streetNumber,
                city,
                postcode,
                floor,
                elevator,
                parameters,
                additionalServices,
                otherAdditionalServices,
                priceItems,

                protocolData {
                  ...on object_ProtocolData {
                    id, key, fullpath,
                    serialNumber, peepholeHeight, doorHeight, confirmations, clientNotes,
                    protocolCreation, authorizedPerson, authorizedPersonID,

                    protocol { ...on asset { id, fullpath, filename, filesize, type, mimetype } },
                    signatureClient { ...on asset { id, fullpath, filename, filesize, type, mimetype } },
                    signatureWorker { ...on asset { id, fullpath, filename, filesize, type, mimetype } },

                    isMagnum, isCertified,
                  }
                },

                selectedConfiguration
                selectedDoorLabel
                selectedDoorLabelExtended
                selectedPrice

                attachments {
                    ... on asset { id, fullpath, filename, filesize, type, mimetype }
                },
                attachmentFolderId
                workerGroup {
                  ... on object_WorkerGroup {
                    id, fullpath, key,
                    workerGroupID, availableMorning, availableAfternoon, availableEvening,
                    color, certificate, address, ratePerKm, commission,
                    leadingWorker {
                      ... on object_Worker { id, fullpath, key }
                    }
                    workers {
                      ... on object_Worker { id, fullpath, key }
                    }
                    apiUser {
                      ... on object_ApiUser { id, fullpath, key }
                    }
                  }
                }
              }
            }
            hash
            viewed
            viewedFromIp
            totalPriceWithVat, totalPriceWithoutVat
          }
        }
      }
    }
  `

  export type Variables = {
    hash: string
    ip?: string
  }

  export type Data = {
    getOrderPreview: OrderPreviewType
  }

  export const execute = async (variables: Variables) => publicClient
    .query<Data, Variables>({ query, variables })
    .then(r => r.data.getOrderPreview.success && r.data.getOrderPreview.order
      ? OrderImport(r.data.getOrderPreview.order)
      : ulog('e', 'GQLPublicOrder(Q) failed', r)
    )
    .catch(e => ulog('e', 'GQLPublicOrder(Q) exception', e))

}
