import { Actions, Maybe } from '@/api/graphql/schema/app.graphql.types'

/**
 * Prechody medzi stavmi objevnavky/poptavky/konfiguracie
 * * Uses Symphony Workflow
 * ? https://gitlab.com/portadesign/pimcore/htdvere-b2b-backend/-/tree/main/config/workflows/
 */
export const ActionEnum = {
  /** Nedefinovaný stav          */ unset:         <const>'',

  // --- Nazvy prechodov medzi stavmi Objednavky/Poptavky   ---  ---  ---  ---  ---  ---
  order: {
    /** Assign to a branch              */ assignInquiry:   <const>'assignInquiry',
    /** Cancel inquiry                  */ cancelInquiry:   <const>'cancelInquiry',
    /** Restore inquiry                  */ restoreInquiry:   <const>'restoreInquiry',
    /** Create order                    */ createOrder:     <const>'createOrder',
    /** Označit neodeslanou objednávku  */ setOrderWaiting: <const>'setOrderWaiting',
    /** Submit for approval             */ submitOrder:     <const>'submitOrder',
    /** Approve order                   */ approveOrder:    <const>'approveOrder',
    /** Approve orderByAdmin            */ approveOrderByAdmin:    <const>'approveOrderByAdmin',
    /** Temporarily reject order        */ rejectOrder:     <const>'rejectOrder',
    /** Complain order                  */ complainOrder:   <const>'complainOrder',
    /** Cancel order                    */ cancelOrder:     <const>'cancelOrder',
    /** Complete order                  */ completeOrder:   <const>'completeOrder',
    /** Waiting for customer inquiry start */ waitingInquiryStart:   <const>'waitingInquiryStart',
    /** Waiting for customer inquiry end */ waitingInquiryStop:   <const>'waitingInquiryStop',
    /** Waiting for protocol */ waitingForProtocol:   <const>'waitingForProtocol',
    /** Restore cancelled order         */ restoreOrder:   <const>'restoreOrder',
    /** Inquiry accepted         */ acceptInquiry:   <const>'acceptInquiry',
    /** Inquiry accepted         */ sendOverviewEmail:   <const>'sendOverviewEmail',
    /** Waiting for customer order start */ waitingOrderStart:   <const>'waitingOrderStart',
    /** Waiting for customer order end */ waitingOrderStop:   <const>'waitingOrderStop',
    /** Waiting for term */ orderWaitingForTerm:   <const>'orderWaitingForTerm',
  },

  // --- Nazvy prechodov medzi stavmi monntaze Konfiguracie ---  ---  ---  ---  ---  ---
  config: {
    /** Assign construction date  */ assignTerm:                    <const>'assignTerm',
    /** Reassign the date again   */ reassignTerm:                  <const>'reassignTerm',
    /** Approve construction date */ approveTerm:                   <const>'approveTerm',
    /** Remove construction date  */ cancelTerm:                    <const>'cancelTerm',
    /** Approve and complete      */ completeAssembly:              <const>'completeAssembly',
    /** Complete with complaints  */ completeAssemblyWithComplaint: <const>'completeAssemblyWithComplaint',
    /** Cancel construction       */ cancelAssembly:                <const>'cancelAssembly',
  },
}

export enum OrderAction {
  createOrder = 'Vytvořit objednávku',
  assignInquiry = 'Předat pobočce',
  cancelInquiry = 'Stornovat',
  submitOrder = 'Odeslat ke schválení',
  approveOrder = 'Potvrdit objednávku',
  approveOrderByAdmin = 'Potvrdit za zákazníka',
  rejectOrder = 'Stornovat',
  completeOrder = 'Uzavřít objednávku',
  cancelOrder = 'Stornovat',
  edit = 'Editovat',
  exportPDF = 'Export my',
  exportPDFPublic = 'Export zákazník',
  waitingInquiryStart = 'Začátek čekaní na zákazníka',
  waitingInquiryStop = 'Ukončení čekaní na zákazníka',
  waitingOrderStart = 'Začátek čekaní na zákazníka u objednávky',
  waitingOrderStop = 'Začátek čekaní na zákazníka u objednávky',
  restoreOrder = 'Obnovit objednávku',
  restoreInquiry = 'Obnovit poptávku',
  acceptInquiry = 'Převzít poptávku',
  sendOverviewEmail = 'Odeslat informační email',
  orderWaitingForTerm = 'Předat k montáži',
}

//
// Action types definition
//

type ActionConstructor<T> = typeof ActionEnum.unset | T[keyof T]
export type PimcoreOrderAction = ActionConstructor<typeof ActionEnum.order>
export type PimcoreConfigAction = ActionConstructor<typeof ActionEnum.config>

export type PimcoreAction =
  | PimcoreOrderAction
  | PimcoreConfigAction

export type PimcoreActionEntry<T extends PimcoreAction = PimcoreAction> = {
  action: T
  enabled: boolean
}

export const PimcoreActionsImport = <T extends PimcoreAction = PimcoreAction>(imported?: Maybe<Actions>[]): PimcoreActionEntry<T>[] => (
  !imported || !imported.length
    ? []
    : imported.map(i => ({
      action: (i?.action || '') as T,
      enabled: i?.enabled || false,
    }))
)

export const PimcoreActionListImport = <T extends PimcoreAction = PimcoreAction>(imported?: Maybe<string>[]): T[] => (
  !imported || !imported.length
    ? []
    : imported.map(i => (i || '') as T)
)

// PimcoreActionList<T>
