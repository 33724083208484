import Vue from 'vue'
import VueI18n from 'vue-i18n'

Vue.use(VueI18n)

export const cs = {
  'assembly': {
    'vacationsAndBlocks': 'Blokace a volno',
    'closeDialog': 'Zavřít kalendář',
    'morning': 'Ráno',
    'afternoon': 'Odpoledne',
    'morningAssemblyTime': 'Ranní montáž',
    'afternoonAssemblyTime': 'Odpolední montáž',
    'eveningAssemblyTime': 'Večerní montáž',
  },
  'roles': {
    'admin': 'HTDveře Admin',
    'billing': 'HTDveře Fakturant',
    'branchMontage': 'Editor pobočky podřízené pobočky',
    'branchMontageBranch': 'Editor pobočky nadřazené pobočky',
    'worker': 'Montážní Skupina',
  },
  'order': {
    'list': {
      'orderNumber': 'Číslo objednávky',
      'name': 'Jméno',
      'city': 'Obec',
      'specification': 'Specifikace',
      'branch': 'Středisko',
      'workerGroup': 'Mont. skupina',
      'installationDate': 'Datum montáže',
      'status': 'Stav',
    },
    'states': {
      'orderNew': 'Nová objednávka',
      'orderWaitForSend': 'Neodeslána',
      'orderForApproval': 'Ke schválení',
      'orderApproved': 'K montáži',
      'orderApprovedForDirectSale': 'K odběru',
      'orderRejected': 'Neschválena zákazníkem',
      'orderCompleted': 'Vyřízena',
      'orderComplaints': 'Vyřízena s připomínkami',
      'orderCancelled': 'Zrušena',
      'orderRestored': 'Obnovená',
      'orderReassigned': 'Objednávka předána',
      'inquiryNew': 'Nová poptávka',
      'inquiryAssigned': 'Předána',
      'inquiryCancelled': 'Zrušena',
      'inquiryWaitingForCustomerStart': 'Čeká na zákazníka',
      'waitingForCustomer': 'Čeká na zákazníka',
      'orderWaitingForProtocol': 'Čeká na protokol',
      'acceptInquiry': 'Převzatá',
      'orderWaitingForCustomerStart': 'Čeká na zákazníka',
      'orderWaitingForTerm': 'Čeká na termín',
      'orderCancelledTerm': 'Zrušený termín',
    },
    'configuration': {
      'assemblyInfo': 'Údaje o montáži',
      'assemblyProtocol': 'Předávací protokol',
      'frameSize': 'Šířka zárubně',
      'peepHoleHeight': 'Výška kukátka',
      'doorHeight': 'Výška dveří',
      'accessories': 'Doplňky',
      'services': 'Služby',
      'pbz': 'PBZ je provozuschopné',
      'fireDoor': 'Protipožární dveře',
      'orderNumber': 'Výrobní číslo dveří',
      'workerNote': 'Poznámky pracovníka',
      'authorizedPersonAssembly': 'Pověřená osoba za převzetí montáže',
      'authorizedWorker': 'Zodpovědný montér',
      'photoDocumentation': 'Fotografická dokumentace',
      'signAgain': 'Podepsat znovu',
      'customerSign': 'Podpis zákazníka',
      'workerSign': 'Podpis zástupce montážní skupiny',
      'backToFirstStepProtocol': 'Zpět na první krok protokolu',
      'generateProtocol': 'Vygenerovat montážní protokol',
      'delete': 'Smazat konfiguraci',
      'edit': 'Upravit konfiguraci',
      'create': 'Vytvořit konfiguraci',
      'createInfo': 'Pro specifikování parametrů dveří nejprve vytvořte konfiguraci.',
      'invalidConfiguration': 'Neplatná konfigurace',
      'installationProtocol' : 'Doklad o montáži',
      'assemblyProtocolName' : 'predavaci-protokol',
      'installationProtocolName' : 'instalacni-protokol',
      'uploadRequiredDocuments': 'Vložte prosím požadované dokumenty',
      'magnumCertificateRequired': 'Pro model Magnum je vyžadován Doklad o montáži',
      'uploadError': 'Při ukládání dokumentů nastala chyba',
      'brick': 'Zdivo',
      'hingesLocation': 'Umístění pantů',
      'doorColor': 'Barva dveří',
      'waitingForCustomer': 'Není možné měnit termín, jelikož objednávka čeká na zákazníka.',
      'fileUploadLabel': 'Vyberte nebo přetáhněte soubory',
      'morning': 'Dopoledne',
      'afternoon': 'Odpoledne',
      'evening': 'Večer',
      'changeDate': 'Změnit termín',
      'cancelDate': 'Zrušit termín',
      'enterDate': 'Zadat termín',
      'installationDate': 'Datum instalace',
      'installationUpdateSuccess': 'Datum montáže bylo uloženo.',
      'installationUpdateError': 'Chyba v ukládání montáže.',
    },
    'message': {
      'orderActionNotAvailable': 'Protokol byl vygenerován, objednávka je již uzavřena.',
      'orderConfigurationUploadError': 'Nepodařilo se aktualizovat konfiguraci - akce na objednávce není povolena.',
      'configurationUpdateError': 'Nepodařilo se aktualizovat konfiguraci objednávky.',
      'orderUpdateSuccess': 'Objednávka aktualizována',
      'configurationStateNotForProcessing': 'Konfigurace není ve stavu k vyřízení.',
    },
    'preview': {
      'buttonPrint': 'Vytisknout objednávku',
      'buttonUploadConfirmation': 'Nahrát podepsanou objednávku',
      'companyMessage': 'Tuto objednávku prosím vytiskněte, podepište, případně orazítkujte a nahrajte pomocí tlačítka Nahrát podepsanou objednávku. Následně potvrďte.',
      'documentLoading': 'Načítám dokument...',
      'messageCorruptedFile': 'Nepovedlo se zaznamenat soubor. Pravděpodobně je poškozený. Zkuste to prosím znovu.',
      'messageDocumentRequired': 'Prosím, nahrajte podepsaný dokument.',
      'messageUploadFailed': 'Nepodařilo se uložit dokument. Zkuste to prosím znovu.',
      'messageUploadSuccess': 'Dokument byl úspěšně uložen.',
      'placeholderAttachDocument': 'Nahrát dokument',
      'titleUploadConfirmation': 'Nahrát podepsaný dokument objednávky',
    },
    'invoice': {
      'verification': 'Ověření',
      'directSaleTransport': 'Způsob dopravy',
      'payByCashOrCardAtCollect': 'Hotově nebo kartou při převzetí',
      'address': {
        'street': 'Ulice',
      },
      'entityNaturalPerson': 'Fyzickou osobu',
      'entityLegalBody': 'Právnickou osobu',
      'taxLiabilityTransferred' : 'Přenesená',
      'taxLiabilityNonTransferred' : 'Nepřenesená',
    },
    'assemblyDate': 'Termín montáže',
    'setDateOrder': 'Zadat Termín',
    'changeDateOrder': 'Změnit termín',
    'customer': 'Zákazník',
    'state': 'Stav',
    'workerGroup': 'Montážní skupina',
    'customerNote': 'Poznámky zákazníka',
    'sendAndClose': 'Odeslat a zavřít',
    'attachProtocol': 'Nahrát protokol',
    'printProtocolTemplate': 'Předtisknout',
    'addPhotoDocumentation': 'Nahrát foto',
    'restoreOrderQuery': 'Obnovit zrušenou objednávku?',
    'orderRestored': 'Objednávka byla obnovena',
    'inquiryRestored': 'Poptávka byla obnovena',
    'closeOrderQuery': 'Uzavřít objednávku?',
    'orderClosed': 'Objednávka byla uzavřena',
    'branchAssignmentError': 'Objednávku se nepodařilo přiřadit pobočce.',
    'totalOrderPrice': 'Souhrnná cena za celou objednávku',
    'currencyWithoutVat' : 'Kč bez DPH',
    'currencyWithVat' : 'Kč s DPH',
    'detail': {
      'allocatedBranch': 'Středisko',
      'unassigned': 'Nepřiřazeno',
      'branchOffice': 'Objednávka přiřazena',
      'missingBirthdate': 'Datum narození je pro fyzické osoby povinné',
      'invalidDate': 'Neplatný formát data',
      'emailMissing': 'Pro zaslání emailu zákazníkovi je nutné mít zadaný kontaktní e-mail',
      'lockedBy': 'Záznam již má otevřený uživatel:',
      'orderHistory': {
        'title': 'Historie objednávky',
        'state': 'Stav',
        'date': 'Datum',
        'updatedBy': 'Změnil',
        'create': 'Vytvořena',
        'update': 'Upravena',
        'assignedBy': 'Převzal',
      },
      'overviewEmail': {
        'emailWasSuccessfullySent': 'Email byl úspěšně odeslán',
      },
      'doorFireproofInfo': 'Zákazník si je vědom, že dveře nejsou protipožární!',
    },
    'resendEmailToCustomer': {
      'success': 'Email byl úspěšně odeslán',
    },
  },
  'inquiry': {
    'list': {
      'inquiryNumber': 'Číslo poptávky',
      'fullName': 'Příjmení a jméno',
      'city': 'Obec',
      'branchOffice': 'Objednávka přiřazena',
      'createdDate': 'Datum vytvoření',
      'status': 'Stav',
    },
    'assignModal': {
      'titleInquiry': 'Předat poptávku pobočce',
      'titleOrder': 'Předat objednávku pobočce',
      'branch': {
        'code': 'Kód',
        'name': 'Název',
        'city': 'Obec',
      },
      'quickPick': 'Rychlý výběr',
      'selectBranch': 'Vybrat',
      'autocompleteTitle': 'Další pobočky',
      'changeCentralBranch': 'Změnit středisko',
      'changeAssignedBranch': 'Změnit zodpovědnou pobočku',
    },
    'detail': {
      'waitingForCustomerToggle': 'Čeká na zákazníka',
      'branchWasAssigned': 'Pobočka byla úspěšně přiřazena.',
    },
  },
  'user': {
    'deleteModal': {
      'title': 'Smazání uživatele',
      'doYouWantRemoveUser': 'Doopravdy chcete smazat uživatele',
      'cancel': 'Zrušit',
      'agree': 'Smazat',
    },
    'firstnameAndSurname': 'Jméno a příjmení',
    'identityCard': 'Občanský průkaz',
    'success': {
      'passwordUpdated': 'Heslo bylo aktualizováno',
      'userUpdated': 'Informace o uživateli byli upraveny.',
    },
    'error': {
      'userAlreadyExists': 'Uživatel s tímto emailem již existuje',
      'branchOfficeAlreadyAssigned': 'Vybraná pobočka je už přiřazena',
      'noJWT': 'Pro pokračování je nutné se znovu přihlásit.',
      'default': 'Při aktualizaci uživatele nastala chyba.',
      'wrongPassword': 'Chybně zadané stávající heslo.',
      'userCreateExists': 'Tento e-mail je již použitý pro jiný účet.',
      'userCreateJwt': 'Pro pokračování je nutné se znovu přihlásit.',
      'userCreateUnknown': 'Chyba při vytváření uživatele. E-mail pravděpodobně používá jiný účet.',
    },
    'passwordChange': 'Změnit heslo',
    'passwordOld': 'Stávající heslo',
    'passwordNew': 'Nové heslo',
  },
  'branch': {
    'branchesHeader': 'Pobočky',
    'deleteModal': {
      'title': 'Smazání pobočky',
      'doYouWantRemoveBranch': 'Doopravdy chcete smazat pobočku',
      'cancel': 'Zrušit',
      'agree': 'Smazat',
    },
    'branchListModal': {
      'title': 'Výpis poboček',
      'searchInput': 'Vyhledávání',
      'selectButton': 'Vybrat',
    },
    'types': {
      'subordinateBranch': 'Podřízená pobočka',
      'superordinateBranch': 'Nadřazená pobočka',
    },
    'code': 'Kód',
    'city': 'Obec',
    'name': 'Name',
    'wholesale': 'Velkoobchod',
    'workerGroupBranchList': {
      'remove': 'Smazat',
    },
    'table': {
      'noData': 'Žádné data k dispozici',
    },
    'detail': {
      'wasSuccessfullyUpdated': 'Pobočka byla úspěšně upravena.',
      'branchType': 'Typ pobočky',
    },
  },
  'global': {
    'backToList': 'Zpět na seznam',
    'itemNotFound': 'Položka nenalezena',
    'dunno': 'Nevím',
    'yes': 'Ano',
    'no': 'Ne',
    'close': 'Zavřít',
    'picture': 'Obrázek',
    'cancel': 'Zrušit',
    'system': 'Systém',
    'city': 'Obec',
    'order': 'Objednávka | Objednávky',
    'note': 'Poznámka',
    'date': 'Datum',
    'save': 'Uložit',
    'complaint': 'Závada',
    'day': 'den | dní',
    'month': 'měsíc',
    'year': 'rok',
    'configuration': 'Konfigurace',
  },
  'api': {
    'messageLoadSettingsError' : 'Některá nastavení se nepovedlo načíst z databáze.',
  },
  'search': {
    'dropdownTitle': 'Objednávky a blokace na základě vyhledávání',
    'lastSearched': 'Poslední hledané',
    'placeholder': 'Vyhledat...',
  },
  'date': {
    'week': 'Týden',
    'month': 'Měsíc',
  },
  'calendar': {
    'title': 'Montážní kalendář',
    'changeTerm': 'Měníte termín pro:',
    'vacationsAndBlocking': 'Blokace a volno',
    'closeCalendar': 'Zavřít kalendář',
    'showEveningTerms': 'Zobrazit večerní termíny',
    'holiday': 'svátek',
    'eventEditDialog': {
      'title': 'Zadání montáže pro objednávku',
      'order': 'Objednávka',
      'morning': 'Ráno',
      'afternoon': 'Odpoledne',
      'evening': 'Večer',
      'date': 'Termín',
      'time': 'Čas',
      'create': 'Vytvořit',
      'configuration': 'Konfigurace',
      'orderPlaceholder': 'Vyhledat objednávku...',
      'blockDate': 'Termín blokace',
      'cancelEvent': 'Zrušit termín',
      'changeEvent': 'Změnit termín',
      'none': '',
      'am': 'dopoledne',
      'pm': 'odpoledne',
      'all': 'celý den',
    },
    'blockModal' : {
      'alreadyExistMontages' : 'Montážní skupina v daném intervalu už má zadané montáže',
      'alreadyExistFreeDays' : 'Montážní skupina má v daném intervalu nepracovní den',
      'collisionMessage' : 'Ve zvolený termín již máte plno',
      'validation': {
        'missingDateFrom': 'Vyplňte datum od',
        'missingDateTo': 'Vyplňte datum do',
        'missingBeginFrom': 'Vyplňte čas montáže',
        'missingWorkerGroup': 'Vyberte montážní skupinu',
      },
      'entryType': {
        'block': 'Blokace',
        'vacation': 'Volno',
      },
      'title': {
        'updateBlock': 'Upravit blokaci',
        'updateVacation': 'Upravit volno',
        'createBlock': 'Zadat blokaci',
        'createVacation': 'Zadat volno',
      },
      'form': {
        'description': 'Popis',
        'time': 'Čas',
        'morningInstallation': 'ráno',
        'afternoonInstallation': 'odpoledne',
        'eveningInstallation': 'večerní',
        'remove': 'Vymazat',
        'cancel': 'Zrušit',
        'updateBlock': 'Upravit blokaci',
        'updateVacation': 'Upravit volno',
        'createBlock': 'Zadat blokaci',
        'createVacation': 'Zadat volno',
      },

    },
    'message': {
      'warningCalendarMissingId': 'Pro nastavení data montáže chybí id konfigurace nebo id skupiny.',
      'warningUseAssemblyFetch': 'Nepovedlo se zapsat termín: {error}.',
      'warningMissingConfiguration': 'Chybějící data konfigurace po úpravě termínu.',
      'warningConfigurationNotToUpdate': 'Stav konfigurace nebude v tomto případě aktualizován.',
      'warningConfigurationUpdate': 'Při aktualizaci konfigurace nastala chyba.',
      'warningConfigurationState': 'Při aktualizaci stavu konfigurace nastala chyba.',
      'warningTermAssign': 'Při přesunu termínu nastala chyba.',
    },
  },
  'filter': {
    'orderNumber': 'Č. objednávky',
    'inquiryNumber': 'Č. poptávky',
    'name': 'Jméno',
    'city': 'Obec',
    'branch': 'Pobočka',
    'workerGroup': 'Mont. skupina',
    'date': 'Datum',
    'state': 'Stav',
    'reset': 'Reset',
  },
  'workerGroupDetail': {
    'shifts': {
      'morning': 'Ranní',
      'afternoon': 'Odpolední',
      'evening': 'Večerní',
    },
    'availability': {
      'monday': 'Pondělí',
      'tuesday': 'Úterý',
      'wednesday': 'Středa',
      'thursday': 'Čtvrtek',
      'friday': 'Pátek',
      'saturday': 'Sobota',
    },
  },
  'reclamation': {
    'linkToOrderModal': {
      'titleLinkToOrder': 'Spojit s objednávkou',
      'searchConfigurations': 'Vyhledat konfiguraci...',
    },
    'assignModal': {
      'autocompleteBranchTitle': 'Pobočky',
      'autocompleteWorkerTitle': 'Montážní skupiny',
      'autocompleteUserTitle': 'Uživatelé',
      'workerId': 'ID',
      'workerAddress': 'Adresa',
      'userName': 'Jméno',
      'userBranch': 'Pobočka',
      'selectWorker': 'Vybrat',
      'selectUser': 'Vybrat',
    },
    'view': {
      'title': 'Koš průserů',
      'new': 'Nová závada',
      'number': 'Číslo závady',
      'name': 'Příjmení a jméno',
      'city': 'Obec',
      'branch': 'Požadavek přiřazen',
      'workGroup': 'Mont. skupina',
      'date': 'Datum podání',
      'daysLeft': 'Zbývá dnů',
      'order': 'Objednávka',
      'state': 'Stav',
    },
    'detail': {
      'title': 'Závada',
      'daysLeft': 'Do vyřízení zbývá',
      'branchOffice': 'Přiřazeno',
      'complaintNumber': 'Číslo závady',
      'creationDate': 'Závada ohlášena',
      'contactTitle': 'Kontaktní údaje',
      'contactName': 'Jméno objednavatele',
      'contactPhone': 'Telefon',
      'contactEmail': 'E-mail',
      'city': 'Obec',
      'street': 'Ulice a č.p.',
      'postcode': 'PSČ',
      'titleComplaintDetail': 'Detail závady',
      'complaintDate': 'Datum montáže',
      'invoiceNumber': 'Číslo faktury',
      'orderNumber': 'Číslo objednávky',
      'complaintDescription': 'Popis problému',
      'complaintAttachments': 'Obrazová příloha',
      'protocolTitle': 'Protokol k závadě',
      'protocolModificationDate': 'Protokol podepsán',
      'protocolState': 'Stav',
      'protocolSignature': 'Podepsal',
      'protocolAttachments': 'Obrazová dokumentace',
      'internalNoteTitle': 'Interní poznámka',
      'publicNoteTitle': 'Veřejná poznámka',
      'historyTitle': 'Historie aktivity',
      'messageConfirmCancel': 'Opravdu chcete stornovat požadavek?',
    },
    'protocol': {
      'backToList': 'Zpět na seznam závad',
      'titleProtocol': 'Protokol k závadě',
      'titleComplaintDetails': 'Identifikace závady',
      'backLink': 'Zpět na seznam',
      'titleClientStatement': 'Vyjádření klienta',
      'labelRepaired': 'Nahlášená závada byla beze zbytku odstraněna?',
      'titleRepairNotes': 'Odstranění závady',
      'labelComplaint': 'Nahlášená závada',
      'labelRepairNote': 'Poznámka o provedení',
      'labelNote': 'Poznámka',
      'titleSignatureWorker': 'Podpis montéra',
      'titleSignatureClient': 'Podpis Zákazníka',
      'buttonSignAgain': 'Podepsat znovu',
      'buttonSaveAndClose': 'Uložit a zavřít',
      'addPhotoDocumentation': 'Nahrát foto dokumentaci',
      'addFiles': 'Nahrát soubory',
      'complaintProtocol': 'Protokol k závadě',
      'complaint': 'Závada',
      'complaintHandedOver': 'Převzata',
      'complaintNotHandedOver': 'Nepřevzata',
      'attachmentHint': 'Vyberte nebo nafoťte obrazovou dokumentaci.',
      'attachmentLabel': 'Nahrát nebo nafotit',
      'photoDocumentation': 'Fotografická dokumentace',
      'uploadFiles': 'Nahrát soubory',
      'assemblyProtocolName' : 'predavaci-protokol',
      'installationProtocolName' : 'instalacni-protokol',
      'reclamationProtocolName' : 'protokol-zavady',

      'messageRequiredDocumentsError': 'Vložte prosím požadované dokumenty',
      'messageProtocolCreateError': 'Nepodařilo se uložit protokol. Zkuste to prosím znovu.',
      'messageProtocolCreateSuccess': 'Protokol byl úspěšně uložen.',
      'messageFilesSaved': 'Dokumenty objednávky byly úspěšně uloženy.',
    },
    'actions': {
      'complaintHandOver': 'Předat závadu',
      'complaintHandOverTitle': 'Předat závadu',
      'linkToOrder': 'Spojit s objednávkou',
      'exportToPDF': 'Exportovat do PDF',
      'printProtocol': 'Předtisknout protokol',
    },
    'transition': {
      'complaintCreate': 'Závada byla vytvořena',
      'complaintHandOver': 'Závada byla předána',
      'complaintRetrieve': 'Závada byla převzata',
      'complaintComplete': 'Závada byla vyřízena',
      'complaintComplain': 'Závada byla vyřízena s připomínkami',
      'requestCancel': 'Požadavek byl stornován',
      'complaintCancel': 'Závada byla stornována',

      'confirmCancel': 'Opravdu chcete stornovat žádost?',
      'assignRequestError': 'Nelze předat závadu',
      'apiUserMissingError': 'Neexistující apiUser ID',
      'updateSuccess': 'Požadavek byl úspěšně aktualizován',
      'updateError': 'Požadavek se nepovedlo aktualizovat, zkuste to znovu za chvíli',
      'invoiceNumberWasUpdated': 'Číslo faktury bylo aktualizováno',
      'invoiceNumberUpdateError': 'Nepodařilo se aktualizovat číslo faktury',
      'messageConfigurationMissingError': 'Není vybrána konfigurace',
      'messageConfigurationAssignSuccess': 'Konfigurace byla úspěšně přidána',
      'messageConfigurationAssignError': 'Konfiguraci se nepodařilo navázat',
    },
    'messages': {
      'noteTooLong': 'Poznámka nesmí být delší než 530 znaků',
      'max530Characters': 'Max. 530 znaků',
      'serviceNotBeingHandled': 'Závada není ve stavu k vyřízení.',
      'userRolesParsingError': 'Nepovedlo se přečíst role uživatele',
    },
    'configuration': {
      'installationUpdateSuccess': 'Datum montáže bylo uloženo.',
      'installationUpdateError': 'Nepodařilo se uložit datum montáže.',
    },
    'states': {
      'requestNew': 'Nový požadavek',
      'requestCanceled': 'Stornovaný požadavek',
      'complaintNew': 'Nová závada',
      'complaintCanceled': 'Stornovaná závada',
      'complaintHandedOver': 'Předaná k vyřízení',
      'complaintIsBeingHandled': 'Vyřizuje se',
      'complaintCompleted': 'Vyřízena',
      'complaintNotCompleted': 'Nevyřízena',
    },
  },
}

export default new VueI18n({
  locale: 'cs',
  messages: {
    'cs': cs,
  },
})
